.container {
    margin-top: 50px;
    text-align: center;
}

.file-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.file {
    align-items: center;
    margin-bottom: 20px;
}

.button {
    margin-top: 10px;
}

.loading-spinner {
    margin-top: 20px;
    text-align: center;
    font-size: 1.2em;
}

.file-input-container {
    text-align: center;
}